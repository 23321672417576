@import url('https://fonts.googleapis.com/css?family=Open+Sans|Playfair+Display+SC');

.App {
	padding: 1em;
}

label {
	display: inline-block;
	width: 100px;
}

header {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* typography */

html {
	font-family: 'helvetica neue', sans-serif;
}

h1 {
	font-family: 'Playfair Display SC';
	text-align: center;
	font-size: '64px';
}

thead th,
tfoot th {
	font-family: 'Rock Salt';
}

th {
	letter-spacing: 2px;
	padding: 10px;
}

td {
	letter-spacing: 1px;
	overflow-x: hidden;
	overflow-y: hidden;
	font-size: 12px;
	padding: 10px;
	text-align: center;
}

/* table */

table {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	overflow: hidden;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.content_td p {
	max-width: 100%;
	max-height: 100px;
	overflow-y: auto;
	text-overflow: ellipsis;
}

tbody tr:nth-child(odd) {
	background-color: pink;
}

img {
	object-fit: scale-down;
	width: 100%;
	height: 100%;
}

/* Column Size for thead */
/* Column size for tbody */
thead tr th,
tbody tr td {
	width: 10%;
}

thead tr th:nth-child(4),
thead tr th:nth-child(5),
tbody tr td:nth-child(4),
tbody tr td:nth-child(5) {
	width: 30%;
}
